import { colors, fontSizes, letterSpacings, lineHeights, mediaQuery, spacing } from "../../core/styles";
import { css } from "@emotion/react";
import { ThemeStyles } from "./utils/useThemePattern";
import { MODULE_PADDING_DESKTOP, MODULE_PADDING_MOBILE } from "../ModulesWithTheme/ModulesWithTheme.style";

export const styles = {
  root: (themeConfig: ThemeStyles) =>
    css({
      position: "relative",
      width: "100vw",
      left: "50%",
      marginLeft: "-50vw",
      textAlign: "center",
      marginTop: themeConfig.topMargin,
      paddingTop: themeConfig.topMargin && spacing.baseUnitMobile6,
      background: themeConfig.background,
      overflow: "hidden",
      [mediaQuery.tabletOrLarger]: {
        textAlign: "left",
      },
    }),
  contentWrapper: (themeConfig: ThemeStyles) =>
    css({
      marginBottom: themeConfig.noMarginBottom ? 0 : MODULE_PADDING_MOBILE,
      [mediaQuery.tabletOrLarger]: {
        minHeight: "240px",
      },
      [mediaQuery.desktopOrLarger]: {
        marginBottom: MODULE_PADDING_DESKTOP,
      },
    }),
  partnerWrapper: () =>
    css({
      display: "flex",
      justifyContent: "center",
      marginBottom: spacing.baseUnitMobile2,
      gap: spacing.baseUnitMobile1,
      [mediaQuery.tabletOrLarger]: {
        justifyContent: "flex-start",
      },
    }),
  partnerNote: (themeConfig: ThemeStyles) =>
    themeConfig.isGreyText
      ? css({
          textTransform: "uppercase",
          fontWeight: 600,
          color: colors.headlineGrey.hex,
          fontSize: fontSizes.body.cat,
          lineHeight: lineHeights.body.cat,
        })
      : css({
          textTransform: "uppercase",
          color: colors.paleBlue.hex,
          fontWeight: 700,
        }),
  partnerLogo: () =>
    css({
      maxHeight: "18px",
    }),
  title: (themeConfig: ThemeStyles) =>
    css({
      marginBottom: spacing.baseUnitMobile2,
      color: themeConfig.titleColor,
    }),
  cta: () =>
    css({
      marginBottom: spacing.baseUnitMobile3,
      [mediaQuery.smallerThanTablet]: {
        marginTop: spacing.baseUnitMobile1,
      },
    }),
  vinSearchWrapper: () =>
    css({
      margin: `${spacing.baseUnitMobile3} 0`,
    }),
  additionalLinkWrapper: () =>
    css({
      marginBottom: spacing.baseUnitMobile3,
    }),
  additionalLink: (themeConfig: ThemeStyles) =>
    css({
      color: themeConfig.linkColor,
      fontSize: fontSizes.body.cat,
      fontWeight: 700,
      lineHeight: lineHeights.body.cat,
      letterSpacing: letterSpacings.body.cat,
      position: "relative",
      textDecoration: "underline",
      [mediaQuery.tabletOrLarger]: {
        marginLeft: spacing.baseUnitMobile1,
      },
      "&::before": {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        left: `-${spacing.baseUnitMobile1}`,
        content: "'›'",
      },
    }),
  foxImageWrapper: (aspectRatio?: string, themeConfig?: ThemeStyles) =>
    css({
      maxWidth: "333px",
      aspectRatio: aspectRatio,
      margin: `${spacing.baseUnitMobile1} auto 0`,
      [mediaQuery.tabletOrLarger]: {
        position: "absolute",
        maxWidth: "390px",
        margin: `${spacing.baseUnitMobile2} auto 0`,
      },
      [mediaQuery.desktopOrLarger]: {
        maxWidth: themeConfig.isBigImage ? "641px" : "496px",
        margin: 0,
      },
    }),
  textWrapper: (themeConfig: ThemeStyles) =>
    css({
      marginBottom: spacing.baseUnitMobile3,
      p: {
        color: themeConfig.descriptionColor,
        fontSize: fontSizes.body.horse,
        lineHeight: lineHeights.body.horse,
        letterSpacing: letterSpacings.body.horse,
        marginBottom: 0,
      },
      b: {
        fontWeight: 700,
      },
    }),
  modalStyle: (themeConfig: ThemeStyles) =>
    css({
      color: themeConfig.linkColor,
      fontSize: fontSizes.body.horse,
      fontWeight: 700,
      letterSpacing: letterSpacings.body.horse,
      lineHeight: lineHeights.body.horse,
      div: {
        margin: `${spacing.baseUnitMobile3} 0`,
      },
    }),
  trustIcons: () =>
    css({
      height: "88px",
      backgroundColor: colors.white.hex,
      display: "flex",
      columnGap: spacing.baseUnitMobile4,
      alignItems: "center",
      justifyContent: "center",
      fontWeight: 700,
      color: colors.headlineGrey.hex,
      flexWrap: "wrap",
      marginBottom: spacing.baseUnitMobile2,
      img: {
        width: "80px",
        height: "40px",
        [mediaQuery.desktopOrLarger]: {
          marginTop: spacing.baseUnitMobile1,
        },
      },
      "& div:first-of-type": {
        width: "100%",
        textAlign: "center",
        margin: `${spacing.baseUnitMobile2} 0 ${spacing.baseUnitMobile1}`,
      },
      [mediaQuery.desktopOrLarger]: {
        margin: 0,
        "& div:first-of-type": {
          width: "auto",
        },
      },
    }),
};
